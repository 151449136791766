<template>
  <div
    v-if="
      ($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.secretario == 'S') &&
        $store.state.fastPermissoes.ativo == 'S' &&
        $store.state.fastPlataformaModulos.modulo_secretaria
    "
  >
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria')"
              >Modo secretaria</a>
            </li>   
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/avaliacoes-modelos')"
              >Modelos de avaliação</a>
            </li>             
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Unidades curriculares</a>
            </li>
          </ul>
        </nav>

        <!-- /breadcrumb -->
      </div>
      <div class="fd-mode-adm-content mt-4">
        <div class="container">          
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Modelo de
                  <span>avaliação</span>
                </h1>

                <img :src="require('@/assets/images/form-effect-01.png')">
              </section>
              <!-- /fd-app-welcome -->
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="card shadow mb-4">
                    <div class="card-header py-3">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-6">
                          <h6 class="m-0 font-weight-bold text-primary">
                            Lista de UCs
                          </h6>
                        </div>
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="row">
                        <div class="col-sm-12 col-md-12 col-lg-4 mb-1">
                          <input
                            type="text"
                            class="form-control form-control-sm"
                            placeholder="Filtro por nome da avaliação"
                          >
                        </div>                          
                      </div>
                      <div class="row">
                        <div class="col-12 table-responsive mt-2">
                          <table class="table table-sm">
                            <thead class="thead-dark">
                              <tr class="text-center">
                                <th class="text-center">
                                  <small class="font-weight-bold">Unidade curricular</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Tipo</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Unidade recuperação</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Próximo módulo</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Próxima Unidade Curricular</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Avaliação recuperação</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Avaliação</small>
                                </th>
                                <th class="text-center">
                                  <small class="font-weight-bold">Ações</small>
                                </th>
                              </tr>
                            </thead>
                            <tbody v-if="fastModeloUcs.loading">
                              <tr>
                                <td
                                  colspan="8"
                                  class="text-center"
                                >
                                 <b-icon
                                    icon="gear-fill"
                                    animation="spin"
                                  /> Carregando dados
                                </td>
                              </tr>
                            </tbody>
                            <tbody v-else-if="fastModeloUcs.paginacao.length">
                              <tr
                                v-for="(e, index) in fastModeloUcs.paginacao"
                                :key="index"
                              >
                                <td class="align-middle text-left">
                                  <small>{{ e.sequencia + ' - ' + e.nome_curso }}</small>
                                </td>
                                <td class="align-middle text-center">
                                  <small v-if="e.tipo_avaliacao == 'N'">Nota</small>
                                  <small v-else>Indefinido</small>
                                </td>
                                <td class="align-middle text-center">
                                  <small v-if="e.id_curso_uc_recuperacao"> {{e.id_curso_uc_recuperacao}}</small>
                                  <small v-else class="text-danger"> Indefinido</small>
                                </td>
                                <td class="align-middle text-center">
                                  <small v-if="e.id_secretaria_curso_proximo_programa"> {{e.id_secretaria_curso_proximo_programa}}</small>
                                  <small v-else class="text-danger"> Indefinido</small>
                                </td>
                                <td class="align-middle text-center">
                                  <small v-if="e.id_secretaria_curso_proximo_uc"> {{e.id_secretaria_curso_proximo_uc}}</small>
                                  <small v-else class="text-danger"> Indefinido</small>
                                </td>
                                <td class="align-middle text-center">
                                  <small v-if="e.id_secretaria_avaliacao_recuperacao"> {{e.titulo_recuperacao}}</small>
                                  <small v-else class="text-danger"> Indefinido</small>
                                </td>
                                <td class="align-middle text-center">
                                  <small v-if="e.id_secretaria_avaliacao_principal"> {{e.titulo_avaliacao}}</small>
                                  <small v-else class="text-danger"> Indefinido</small>
                                </td>  
                                <td class="align-middle text-center">
                                  <button
                                    class="btn btn-sm btn-primary pt-0 pb-0 mr-2"
                                    @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/avaliacao-modelo/' + e.id_avaliacao_modelo + '/uc/' + e.id_secretaria_avaliacao_uc)"
                                  >
                                    <small>Editar</small>
                                  </button>
                                </td>                              
                              </tr>
                            </tbody>
                            <tbody v-else>
                              <tr>
                                <td
                                  colspan="8"
                                  class="text-center"
                                >
                                  Nenhum UC encontrada nesse modelo de avaliação 
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div class="col-12 text-center mt-4">
                          <Pagination
                            :page-size="10"
                            :items="fastModeloUcs.filtro"
                            @changePage="
                              fastModeloUcs.paginacao = $event
                            "
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!-- fd-app-meus-cursos -->
              <div class="mt-5 mb-5">              
                <a
                  class="btn btn-red-hollow"
                  :href="
                    '/plataforma/' +
                      $route.params.id_plataforma +
                      '/secretaria'
                  "
                  @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/secretaria/avaliacoes-modelos')"
                >
                  <b-icon-arrow-return-left />
                  Voltar 
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Modals -->
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";
import Pagination from "../components/Pagination";

export default {
  name: "HomeInternoSecretariaAvaliacaoModelo",
  components: {Pagination},
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
      // Modelo UCs
      fastModeloUcs: {
        total: [],
        filtro: [],
        paginacao: [],
        loading: true
      },      
    };
  },
  mounted: function () {
    if (this.$route.params.id_plataforma && this.$route.params.id_avaliacao_modelo) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'secretario')
        .then(() => {
          if (this.$store.state.fastPlataformaModulos.modulo_secretaria) {
            // Recupera modelo ucs
            this.getModeloUcs(this.$route.params.id_plataforma, this.$route.params.id_avaliacao_modelo);
          } else {
            this.$store.state.fastCarregando = false;
            this.exibeToasty("Módulo não disponível para sua plataforma", "error");
          }
            
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    } else {
      this.$store.state.fastCarregando = false;
      this.exibeToasty("Você não possui permissões", "error");
    }
  },
  methods: {
    async getModeloUcs(id_plataforma, id_avaliacao_modelo){
      this.promiseGetFastApi("api/fast_secretaria_avaliacao_uc/lista", "id_plataforma=" + id_plataforma + "&id_avaliacao_modelo=" + id_avaliacao_modelo)
      .then(obj => {
        console.log("api/fast_secretaria_avaliacao_uc/lista", obj)
        if (obj.length) {
          this.fastModeloUcs.total = obj
          this.fastModeloUcs.filtro = obj
        }
        else {
          this.fastModeloUcs.total = []
          this.fastModeloUcs.filtro = []
        }            
        this.$store.state.fastCarregando = false;
        this.fastModeloUcs.loading = false
      }).catch(e => { 
        this.exibeToasty("Erro ao buscar UCs do modelo de avaliação", "error");
        this.$store.state.fastCarregando = false;
        this.fastModeloUcs.loading = false
    })
    },
  },
};
</script>

<style scope>
.blink_me {
  animation: destacaBotao 500ms alternate infinite ease-in;
}

@keyframes destacaBotao {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}
.tabs-component {
  margin: 1em 0;
}

.tabs-component-tabs {
  border: solid 1px #ddd;
  border-radius: 6px;
  margin-bottom: 5px;
}

@media (min-width: 992px) {
  .tabs-component-tabs {
    border: 0;
    align-items: stretch;
    display: flex;
    justify-content: flex-start;
    margin-bottom: -1px;
  }
}

.tabs-component-tab {
  color: #999;
  font-size: 14px;
  font-weight: 600;
  margin-right: 0;
  list-style: none;
}

.tabs-component-tab:hover {
  color: #666;
}

.tabs-component-tab.is-active {
  color: #000;
}

.tabs-component-tab.is-disabled * {
  color: #cdcdcd;
  cursor: not-allowed !important;
}

@media (min-width: 992px) {
  .tabs-component-tab {
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 3px 3px 0 0;
    margin-right: 0.5em;
    transform: translateY(0px);
    transition: transform 0.3s ease;
  }

  .tabs-component-tab.is-active {
    border-bottom: solid 1px #fff;
    z-index: 2;
    transform: translateY(0);
  }
}

.tabs-component-tab-a {
  align-items: center;
  color: inherit;
  display: flex;
  padding: 0.75em 1em;
  text-decoration: none;
}

.tabs-component-panels {
  padding: 4em 0;
}

@media (min-width: 992px) {
  .tabs-component-panels {
    border-top-left-radius: 0;
    background-color: #fff;
    border: solid 1px #ddd;
    border-radius: 0 6px 6px 6px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
    padding: 2em;
  }
}
.prefix,
.suffix {
  align-items: center;
  border-radius: 1.25rem;
  display: flex;
  font-size: 0.75rem;
  flex-shrink: 0;
  height: 1.25rem;
  justify-content: center;
  line-height: 1.25rem;
  min-width: 1.25rem;
  padding: 0 0.1em;
}

.prefix {
  background-color: #d1e8eb;
  color: #0c5174;
  margin-right: 0.35em;
}

.suffix {
  background-color: #c03;
  color: #fff;
  margin-left: 0.35em;
}

@media (min-width: 992px) {
  .suffix {
    position: absolute;
    right: -0.6em;
    top: -0.725em;
  }
}
</style>
